import React from "react"
import { Link } from "gatsby"
import NavigationBar from "../components/Navbar"
import LiveCourses from "../components/LiveCourseComponent"
import DsaInterviewCourseImg from "../img/data-structures-and-algorithms-course.svg"
import DsaInterviewImg from "../img/data-structures-and-algorithms.png"
import { Helmet } from "react-helmet"
import { dsaCourseModuleList } from "../data/dsaCourseData"
import loadable from "@loadable/component"
const Footer2 = loadable(() => import("../components/footer3"))
const Scroll = loadable(() => import("../components/scroll"))
const SocialMedia = loadable(() => import("../components/social-media"))
const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const CoursesAndBlogs = loadable(() => import("../components/CoursesAndBlogs"))

const TableItem = ({ index, title, link }) => (
  <tr className="h-12 bg-white shadow-md border">
    <td className="px-2 py-2">
      <div className="flex items-center">
        <div className="flex text-sm w-6 h-6 sm:text-xs md:text-sm xl:text-base 2xl:text-lg items-center justify-center text-gray-900">
          {index}
        </div>
        <div className="ml-4">
          <div className="text-new-blue hover:underline text-sm sm:text-xs md:text-sm xl:text-base 2xl:text-lg">
            <Link to={link}>{title}</Link>
          </div>
        </div>
      </div>
    </td>
  </tr>
)

const ModuleTable = ({ moduleTitle, items }) => (
  <div className="bg-white">
    <h3 className="text-base sm:text-sm md:text-base text-center xl:text-lg 2xl:text-xl text-gray-900 tracking-wider">
      <span>{moduleTitle}</span>
    </h3>
    <table className="mt-4 w-full">
      <tbody>
        {items.map(({ chapterNumber, chapterName, path }) => (
          <TableItem
            key={chapterNumber}
            index={chapterNumber}
            title={chapterName}
            link={path}
          />
        ))}
      </tbody>
    </table>
  </div>
)

const DsaCoursePage = () => (
  <div>
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <title>DSA Interview Courses</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="description"
        property="og:description"
        content="A self-paced DSA course by EnjoyAlgorithms to master essential data structures and algorithms concepts and problem-solving techniques to crack the coding interview."
      />
      <meta name="author" content="Shubham Gautam" />
      <meta name="image" property="og:image" content={DsaInterviewImg} />

      <meta
        name="keywords"
        content="dsa interview preparation, enjoyalgorithms dsa course, dsa online course, dsa course enjoy algorithms, data structures and algorithms, algorithm in data structure, data structures and algorithms online course, dsa course, dsa self-paced course, coding interview course, best algorithms and data structures course, dsa interview preparation course"
      />

      <meta
        name="title"
        property="og:title"
        content="Data Structures and Algorithms Interview Course"
      />
    </Helmet>
    <div className="sm:sticky top-0 z-50">
      <NavigationBar />
    </div>

    <div className="bg-light-white">
      <div className="container mx-auto px-5 md:px-7 lg:px-8 xl:px-10">
        <div className="md:grid md:grid-cols-12">
          <div className="pt-4 md:pt-6 xl:pt-8 md:col-span-7">
            <h1 className="tracking-wider text-xl md:text-2xl xl:text-3xl text-gray-900 text-left sm:text-center md:text-left">
              DSA Interview Course
            </h1>
            <p className="tracking-wider mt-3 text-base sm:text-sm xl:text-base 2xl:text-lg text-gray-600 xl:max-w-2xl ">
              A free self-paced DSA course for students and professionals who want to
              learn data structures and algorithms concepts, master problem-solving approaches, and crack the
              coding interview. Enjoy learning algorithms!
            </p>
            <h2 className="sm:hidden lg:block mt-6 tracking-wider text-gray-900 text-lg md:text-xl xl:text-2xl">
              What will you learn?
            </h2>
            <div className="mt-6 sm:hidden grid gap-6 sm:grid-cols-2 lg:grid text-gray-600 tracking-wider text-base sm:text-sm xl:text-base 2xl:text-lg">
              <p> &#10003; Problem Solving Techniques</p>
              <p> &#10003; Designing Efficient Solutions</p>
              <p> &#10003; Implementing Correct Code</p>
              <p> &#10003; Analysis and Code Optimization</p>
            </div>
          </div>

          <div className="mt-6 md:mt-4 md:col-span-5 flex justify-center">
            <img
              className="object-cover pointer-events-none w-96 h-52 sm:w-128 sm:h-72 md:w-80 md:h-44 lg:w-112 lg:h-64 xl:w-128 xl:h-72 2xl:w-144 2xl:h-80"
              src={DsaInterviewCourseImg}
              alt="enjoyalgorithms dsa course"
            />
          </div>
        </div>

        <h2 className="tracking-wider text-gray-900 sm:mt-2 md:-mt-4 hidden sm:block lg:hidden text-lg 2xl:text-2xl">
          What will you learn?
        </h2>

        <div className="mt-4 hidden tracking-wider sm:grid lg:hidden text-base sm:text-sm xl:text-base 2xl:text-lg text-gray-600 gap-4 grid-cols-2">
          <p> &#10003; Problem Solving Techniques</p>
          <p> &#10003; Designing Efficient Solutions</p>
          <p> &#10003; Implementing Correct Code</p>
          <p> &#10003; Analysis and Code Optimization</p>
        </div>
        <LiveCourses />

        <h2 className="mt-12 tracking-wider text-center text-gray-900 text-lg lg:text-xl xl:text-2xl">
          DSA Course Modules &#8595;
        </h2>

        <div className="sm:flex sm:justify-between">
          <Link to="/shubham-gautam/">
            <div className="z-30 mt-4 sm:mt-6 text-center tracking-wider text-sm md:text-base 2xl:text-lg justify-end py-1 text-gray-600 hover:text-new-blue underline">
              <p> Mentor and Author: Shubham Gautam </p>
            </div>
          </Link>

          <div className="flex justify-end">
            <SocialMedia />
          </div>
        </div>

        <div className="mt-4 text-center sm:w-72">
          <a
            href="https://www.enjoyalgorithms.com/crack-coding-interviews/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="z-30 tracking-wider text-base lg:text-lg justify-center flex shadow px-4 py-2 rounded border border-new-purple text-white bg-white hover:bg-new-purple text-new-purple hover:text-white transition duration-150">
              <p> Try new look of this course </p>
            </div>
          </a>
        </div>

        <div className="mt-6 md:mt-8 xl:mt-10 grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 text-base sm:text-sm xl:text-base tracking-wider">
          {dsaCourseModuleList.map(module => (
            <ModuleTable
              key={module.moduleNumber}
              moduleTitle={module.moduleName}
              items={module.chapters}
            />
          ))}
        </div>

        <div className="z-30 mt-6 tracking-wider text-sm md:text-base 2xl:text-lg py-2 text-gray-600">
          <p>
            Note: In the coming future, we will publish more concept and problem
            blogs related to queues, linked lists, heaps, graphs, backtracking,
            greedy algorithms, bit manipulation, number theory, etc. We are
            looking forward to your critical feedback and reviews. Enjoy
            algorithms!
          </p>
        </div>

        <div className="mt-6">
          <NewsletterStrip />
        </div>

        <div className="mt-6">
          <CoursesAndBlogs />
        </div>

        <Scroll showBelow={250} />
        <div className="mt-4">
          <Footer2 />
        </div>
      </div>
    </div>
  </div>
)

export default DsaCoursePage
